.verification_container {
    height: 100px;
    justify-content: center;
    margin-top: 60px;
}

.verification_character {
    font-family: 'Avenir';
    font-size: 47px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 100px;
    text-align: center;
    color:#081647;
    background-color: none;
    border: 1px solid #BAC3CE ;
    
    border-radius: 20px;
    margin-left: 21px;
    max-width: 73px;
}

.verification_character:first-child {
    margin-left: 0;
}

.verification_character--inactive {
    /* background-color: #F1F0F0 !important; */
    /* color: red !important; */
}

.verification_character--selected {
    border: 1px solid #3553CD !important;
    /* color: black */
}

.verification_container_phone {
    height: 58px;
    justify-content: center;
    margin-top: 40px;
}

.verification_character_phone {
    font-family: 'Avenir';
    font-size: 27px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 58px;
    text-align: center;
    color: black;
    background-color: #F1F0F0;
    border: 1px solid transparent;
    border-radius: 11.5px;
    margin-left: 12px;
    max-width: 43px;
}
.verification_character_phone:first-child {
    margin-left: 0;
}
