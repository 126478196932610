.carousel-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;}
.carousel-body {
    height: 100%;

}
.carousel-item {
    display: inline-block;
    width: 100%;
    height: 100%;
}
.carousel-footer {
    position: absolute;
    width: 100%;
    bottom: 0;


}
.indicators-container {
    text-align: center;
    padding: 0;
    align-self: center;

}
.indicators-item {
    list-style: none;
    display: inline-block;

    width: 6px;
    height: 6px;
    background-color: #D8D8D8;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    transition: .3s;
    margin: 0 4px;
    border-radius: 5px;


}   .active {
        opacity: 1;
        background-color: #000000;
    }
.btn-container {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);







}
.btn-direction {
    padding: 0;
    margin: 0;
    height: 42px;
    line-height: 38px;
    width: 36px;
    cursor: pointer;
    transition: .3s;
    border-radius: 50%;
    background-color: rgba(31,45,61,.11);
    color: #fff;
    top: 50%;
    z-index: 10;
    text-align: center;
    font-size: 12px;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;

}
.pre {
    left: 12px;
}
.next {
    right: 12px;
}

/* .carousel-container:hover .btn-direction{
    opacity: 1;

    &.pre {
        left: 24px;
    }

    &.next {
        right: 24px;
    } */
