


.home__hero-section{
    /* color: red; */
    /* padding: 160px 0; */
    /* align-items: center; */
}

.home__hero-row{
    align-items: center;
    /* background-color: gray; */
    justify-content: center;
    width: 100%;
    margin-top: 32px;
}

.row{
    display: flex;
    margin-bottom: -15px;
    margin-left: -15px;
    margin-right: -15px;

    flex-wrap: wrap;
    align-content: stretch;
    /* background-color: blue; */
    
}

.content_container{
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    flex:1;
    max-width: 70%; 
    flex-basis: 50%;
    /* background-color: red; */
    align-items: center;
    justify-content: center;
}

.home__hero-text-wrapper{
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
    /* background-color:greenyellow; */
    /* align-items: center; */
    /* justify-content: center; */
}

.top-line{
    /* color:green; */
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
}

.heading{
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #272722;
    /* background-color: green; */
    text-align: center;
    width: 100%;

}

.dark{
    color: #1c2237;
}

.darkBg{
    background-color: #1c2237;
}

.home__hero-subtitle{
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    /* background-color: yellow; */
    text-align: center;
}

.home__hero-img-wrapper{
    max-width: 400px;
    /* background-color: blue; */
    width: 60%;
}

.home__hero-img{
    /* max-width: 95%; */
    /* padding-right: 0; */
    /* margin:0 0 0 10px; */
    width: 100%;
    /* background-color: aqua; */
}
.home__button-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: yellow; */
    /* align-items: center;
    justify-content: center; */
    /* padding-left: 100px;
    padding-right: 100px; */


}
img{
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}

@media screen and (max-width:991px){

    /* .col{
        max-width: 100%;
        flex-basis: 100%;
    } */
}

@media screen and (max-width: 768px){
    /* .home__hero-text-wrapper{
        padding-top: 65px;
    } */
    /* .col{
        max-width: 100%;
        flex-basis: 100%;
    } */



}