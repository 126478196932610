.agreementContent::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    background-color: #BAC3CE;
    border-radius: 4px;
}
.agreementContent::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #8F98A6;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}