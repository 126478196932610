#loading-bar-spinner {
    align-items: center;
}


#loading-bar-spinner.spinner {
    /*left: 50%;*/
    /*margin-left: -20px;*/
    /*top: 50%;*/
    /*margin-top: -20px;*/
    /*position: absolute;*/
    margin-right: 10px;
    z-index: 19 !important;
    animation: loading-bar-spinner 800ms linear infinite;
}

#loading-bar-spinner.spinner .spinner-icon {
    width: 20px;
    height: 20px;
    border:  solid 4px transparent;
    border-top-color:  #0173F9 !important;
    border-left-color: #0173F9 !important;
    border-radius: 50%;
}

@keyframes loading-bar-spinner {
    0%   { transform: rotate(0deg);   transform: rotate(0deg); }
    100% { transform: rotate(360deg); transform: rotate(360deg); }
}
