.gradient-text:last-child th {
    color: transparent;
    background:linear-gradient(227.05deg, #FF0069 30%, #2B00FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient-text-phone{
    color: transparent;
    background:linear-gradient(227.05deg, #FF0069 30%, #2B00FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}