.body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bg {
    padding: 5%;
    width: 20vw;
}

.content {
    height: 44px;
    width: 349px;
    color: #1C2237;
    font-family: Avenir;
    font-size: 32px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 44px;
    text-align: center;
}

.return {
    height: 55px;
    width: 200px;
    border-radius: 20px;
    background-color: #0173F9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: transparent;
    margin-bottom: 5%;
}

.buttonContent {
    height: 25px;
    width: 95px;
    color: #FFFFFF;
    font-family: Avenir;
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
}
