.navbar {
    background:white;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    border-bottom: 1px solid #D8D8D8
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 100%;
    /* background-color: gray; */
    align-items: center;
  }
  
  .container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
    /* background-color: orange; */
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
    height: 100%;
  }
  
  .navbar-icon {
    margin-right: 0.5rem;
    height: 24px;
    /* background-color: aquamarine */
  }

  .nav-changeLanguage{
    border-width: 0ch;
    font-size: 18px;
    border-color:transparent;
    margin-left: 16px;
    margin-right: 16px;
    background-color: transparent;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: flex-end;
  }
  
  .nav-item {
    /* height: 120px; */
    height: 100%;
    border-bottom: 2px solid transparent;
    margin-right: 60px;
  }
  
  .nav-item:hover {
    border-bottom: 2px solid #333333;
  }
  
  .nav-links {
    color: #333333;
    display: flex;
    align-self: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    /* height: 100%; */
    /* background-color: red; */
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .menu-icon {
    display: none;
  }
  .seperate-line{
    color: red;
    height: 1px;
    width: 100%;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      flex-direction: column;
      width:100vw;
      /*height: 40vh;*/
      position: absolute;
      top: 60px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      /* justify-content: center; */
      align-items: center;
      padding-left: 0%;
      padding-right: 0%;
      margin-left: 0%;
      margin-right: 0%;
      overflow: hidden;
      opacity: 0;
    }
  
    .nav-menu.active {
      /* background:blueviolet; */
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
      align-items: center;
      /* opacity: 0.5; */
      background-color: rgba(0, 0, 0, 0.8)
      
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem 0px;
      margin: auto;
      width: 92.5%;
        display: table;
        color:white;
      /* background-color: yellow; */


      
    }
  
    .nav-links:hover {
        color:#f16049;
      transform: scale(1.2);
      transition: all 0.3s ease;
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
        
      width: 100%;
      align-self: center;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-btn {
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 120px;
      
      /* background-color: #333333; */
      
    }
  }

  @media screen and (max-width: 960px) {
    .nav-item {
      /* height: 120px; */
      height: 100%;
      border-bottom: 2px solid transparent;
      margin-right: 0px;
    }
  }