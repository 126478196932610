.article .box {
    height: 40px;
    overflow: hidden;
    position: relative;
}
.article .box::before {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    content: '';
    height: 10px;
    position: absolute;
}
.article .box::after {
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    content: '';
    height: 10px;
    position: absolute;
}

.article p {
    float: left;
}

.article ul {
    float: right;
    margin: 0;
    padding: 0;
    -webkit-animation: scrollUp 12s ease-in-out infinite normal;
    animation: scrollUp 12s ease-in-out infinite normal;
}
.article ul li {
    opacity: 1;
    height: 20px;
    padding: 10px;
    list-style: none;
}

@-webkit-keyframes scrollUp {
    from {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    to {
        -webkit-transform: translateY(-80%);
        transform: translateY(-80%);
    }
}

@keyframes scrollUp {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    8.33% {
        -webkit-transform: translateY(-20%);
        transform: translateY(-20%);
    }
    25% {
        -webkit-transform: translateY(-20%);
        transform: translateY(-20%);
    }
    33.33% {
        -webkit-transform: translateY(-40%);
        transform: translateY(-40%);
    }
    50% {
        -webkit-transform: translateY(-40%);
        transform: translateY(-40%);
    }
    58.33% {
        -webkit-transform: translateY(-60%);
        transform: translateY(-60%);
    }
    75% {
        -webkit-transform: translateY(-60%);
        transform: translateY(-60%);
    }
    83.33% {
        -webkit-transform: translateY(-80%);
        transform: translateY(-80%);
    }
    100% {
        -webkit-transform: translateY(-80%);
        transform: translateY(-80%);
    }
}

@media screen and (max-width:600px) {
    .article ul {
        float: unset;
    }

    .article p{
        max-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}
