.styledTableRow {
    background-color: #F9F9F9;
    border-bottom: solid 18px white;
}
.MuiTableRow-root td:first-child {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.MuiTableRow-root td:last-child {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}
