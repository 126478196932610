.process__container{
    /* height: 150px; */
    width: 100%;
    /* background-color: blue; */
    position: relative;

}

.bottom__container{
    background-color: #102336;
    display: flex;
    align-items: center;
    justify-content: center;

}

.process__textAreaWrapper{
    /* margin-left: 12%; */
    margin-top: 80px;
    margin-bottom: 80px;
    /* background-color: #6496E9; */
    margin-left: 12%;
    margin-right: 12%;


}

.precess__title_container{
    background-color: #6496E9;
    width: 420px;
    height: 50px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

}

.precess__title_container_sell{
    background-color: #DC525F;
    width: 420px;
    height: 50px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

}

.process__title{
    color: white;
    font-size: 20px;

}

.process__detial_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin-left: 24px; */
    

}

.process__detail_index{
    font-size: 18px;
    color: #888888;
}
.process__detail{
    font-size: 16px;
    color: #272727;
    margin-left: 8px;
}


.process__imageAreaWrapper{
    position: absolute;
    width: 38%;
    bottom:0;
    right: 0;

}

.seperateLine{
    display: flex;
    align-self: center;
    /* width: 95%; */
    height: 1px;
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 24px;
    background-color: #D5D5D5;
}

.bottom__image{

    margin: 40px;
    /* background-color: yellow; */
}


@media screen and (max-width:991px){

    /* .col{
        max-width: 100%;
        flex-basis: 100%;
    } */

    .process__imageAreaWrapper{
        position: absolute;
        /* width: 38%; */
        width: 240px;
        bottom:0;
        right: 0;
        opacity: 0.5;
    
    }
}

@media screen and (max-width: 768px){
    /* .home__hero-text-wrapper{
        padding-top: 65px;
    } */
    /* .col{
        max-width: 100%;
        flex-basis: 100%;
    } */

    .process__imageAreaWrapper{
        position: absolute;
        /* width: 38%; */
        width: 240px;
        bottom:0;
        right: 0;
        opacity: 0.0;
    
    }

    .process__container{
        /* height: 150px; */
        width: 100%;
        /* background-color: blue; */
        position: relative;
        /* align-items: center; */
        /* display: flex; */
        justify-content: center;
    }

 
    .seperateLine{
        display: flex;
        align-self: center;
        /* width: 95%; */
        height: 1px;
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 8px;
        background-color: #D5D5D5;
    }


    .process__textAreaWrapper{
        /* margin-left: 12%; */
        margin-top: 80px;
        margin-bottom: 80px;
        /* background-color: #6496E9; */
        margin-left: 8%;
        margin-right: 8%;
    
    
    }

    .precess__title_container{
        background-color: #6496E9;
        width: 100%;
        height: 50px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
    
    }

    .precess__title_container_sell{
        background-color: #DC525F;
        width: 100%;
        height: 50px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
    
    }

    .process__title{
        color: white;
        font-size: 16px;
    
    }
    

}


