.chatContainer {
    width: 100%;
    position: fixed;
    bottom: 15%;
    left: 90%;
}

.chatBtn {
    background-color: #000C75;
    color: white;
    marginRight: 0;
    borderRadius: 0;
    textTransform:'none';
}
