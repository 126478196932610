
.contact__row{
    display: flex;
    align-items: center;
    /* background-color: #272722 */
}
.contact__header{
    transition: all 0.3s ease-out;
    background-color: #102336;
    height: 200px;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact__seperateLine{
    display: flex;
    align-self: center;
    /* width: 95%; */
    height: 1px;
    /* margin-left: 80px;
    margin-right: 80px; */
    margin-top: 60px;
    /* margin-bottom: 24px; */

    background-color: #D5D5D5;
}



.contact__heading{
    /* margin-bottom: 24px; */
    font-size: 38px;
    line-height: 1.1;
    font-weight: 600;
    color:white;
    /* background-color: green; */
    text-align: center;
    width: 100%;
    margin-bottom: 0%;

}



.contact__fillInfoContainer{
    /* background-color: bisque; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 92%;

}

.contact__textInputAreaWrapper{
    /* background-color: aqua; */
    width: 100%;
    justify-content: space-between;
    margin-top: 24px;
}

.contact_textField{
    width: 600px;
    /* margin: 24px; */
    
    /* background-color: yellow */
}


.contact__pickerWrapper{
    width: 100%;
    /* background-color: blue */
}

.contact__SelectionRow{
    width: 100%;
    /* background-color: red */
}

.contact__selectCoinContainer{
    flex-direction: column

}

.contact__subtitle{
    /* margin-bottom: 35px; */
    font-size: 16px;
    /* line-height: 24px; */
    /* background-color: yellow; */
    text-align: left;
    /* background-color: blueviolet; */
    margin-bottom: 0%;
    color: white;
    font-weight: 200
}


.contact__subtitle-dark{
    /* margin-bottom: 35px; */
    font-size: 16px;
    /* line-height: 24px; */
    /* background-color: yellow; */
    text-align: left;
    /* background-color: blueviolet; */
    margin-bottom: 0%;
    color: #272727;
    font-weight: 200;
    margin-top: 24px;
    margin-bottom: 16px;
}

.contact__SectionTitle{
    font-size: 28px;
    color: #757575;
    font-weight: 100;
    text-align: left;
    align-self: flex-start;
}

.contact__getQuoteButton{
    width: 100%;
    height: 60px;
    color: white;
    background-color: #102336;
    border-width: 0ch;
    border-radius: 4px;
    margin-top: 40px;
    font-size: 24px
}

.contact__getQuoteButton-disabled{
    width: 100%;
    height: 60px;
    color: white;
    background-color: #102336;
    border-width: 0ch;
    border-radius: 4px;
    margin-top: 40px;
    font-size: 24px;
    opacity: 0.5;
}

.contact__getQuoteButton:hover{
    /* transform: scale(1.06); */
    transition: all 0.3s ease-out;
    background-color:#16385A;
}


/* .contact__alertView{
    background-color: #102336;
    width: 100%;
} */


@media screen and (max-width: 768px){
    .contact__header{
        /* background-color: red; */
        height: 0px;
        transition: all 0.3s ease-out
        /* width: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center; */
    }
    .contact__textWrapper{
        opacity: 0;
    }
}